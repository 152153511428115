import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { TelegramIcon, YoutubeIcon, DiscordIcon } from "../Svg";
export var footerLinks = [
    {
        label: "About",
        items: [
            {
                label: "Contact",
                href: ""
            },
            {
                label: "Blog",
                href: ""
            },
            {
                label: "Community",
                href: ""
            },
            {
                label: "—"
            }, 
        ]
    },
    {
        label: "Help",
        items: [
            {
                label: "Customer",
                href: "Support https://docs.defipower.com/contact-us/customer-support"
            },
            {
                label: "Troubleshooting",
                href: "https://docs.defipower.com/help/troubleshooting"
            },
            {
                label: "Guides",
                href: "https://docs.defipower.com/get-started"
            }, 
        ]
    },
    {
        label: "Developers",
        items: [
            {
                label: "Github",
                href: ""
            },
            {
                label: "Documentation",
                href: ""
            },
            {
                label: "Bug Bounty",
                href: ""
            },
            {
                label: "Audits",
                href: ""
            },
            {
                label: "Careers",
                href: ""
            }, 
        ]
    }, 
];
export var socials = [
    {
        label: "Telegram",
        icon: TelegramIcon,
        href: "https://t.me/defipower_official"
    },
    {
        label: "Telegram",
        icon: TelegramIcon,
        href: "https://t.me/egoldchain"
    },
    // {
    //   label: "Reddit",
    //   icon: RedditIcon,
    //   href: "https://reddit.com/r/pancakeswap",
    // },
    // {
    //   label: "Instagram",
    //   icon: InstagramIcon,
    //   href: "https://instagram.com/pancakeswap_official",
    // },
    // {
    //   label: "Github",
    //   icon: GithubIcon,
    //   href: "https://github.com/cloudtxchain",
    // },
    {
        label: "Discord",
        icon: DiscordIcon,
        href: "https://discord.gg/Kg9wGKmk"
    },
    {
        label: "Youtube",
        icon: YoutubeIcon,
        href: "https://www.youtube.com/@Defipower_official"
    }, 
];
export var langs = _to_consumable_array(Array(20)).map(function(_, i) {
    return {
        code: "en".concat(i),
        language: "English".concat(i),
        locale: "Locale".concat(i)
    };
});
